

export const data = [{
    inicio:{
        logoPrincipal :'logos/logo-principal-512.png',
        titleHome:{
            word:'H',
            word1:'o',
            word2:'l',
            word3:'a',
            word4:',',
        },
        titleHome2:{
            word5:'M',
            word6:'e',
            word19:'_',
            word7:'L',
            word8:'l',
            word9:'a',
            word10:'m',
            word11:'o',
            word20:'_',
            word12:'J',
            word13:'u',
            word14:'l',
            word15:'i',
            word16:'a',
            word17:'n',
            word18:',',
        },
        titleHome3:{
            word19:'Tecnólogo',
            word20:'_',
            word21:'en',
            word22:'_',
            word23:'Desarrollo',
            word24:'_',
            word25:'de',
            word26:'_',
            word27:'Software',
            
        },
        paragraphHome:'Soy estudiante de Tecnología en Desarrollo de Software en la UNAD. Mi pasión por crear interfaces de usuario intuitivas y dinámicas, combinada con mi formación en la UNAD, me ha llevado a desarrollar habilidades sólidas en HTML 5, CSS 3, BOTSTRAP 5, JavaScript, React js entre otros. \n\nLa experiencia adquirida en la universidad me ha permitido trabajar en proyectos desafiantes, donde he aplicado mis conocimientos para crear soluciones innovadoras. Soy un profesional organizado, proactivo y con una gran capacidad para aprender y adaptarme a nuevas tecnologías.\n\nEstoy buscando oportunidades para poner en práctica mis conocimientos y colaborar en proyectos que me permitan crecer profesionalmente y contribuir al desarrollo de productos digitales de alta calidad.\n\nHagamos algo especial!',
    },
    about:{
        url:'/sobre-mi',
        name:'Mision Vision',
        text:{
            p1:'Misión: Crear experiencias digitales innovadoras y memorables que empoderen a los usuarios a través de interfaces intuitivas y visualmente atractivas. Como profesional en desarrollo de software, mi objetivo es aplicar mis conocimientos y habilidades para diseñar y desarrollar soluciones digitales que resuelvan problemas reales y superen las expectativas de los usuarios.',
            p2:' - Habilidades en programacion con enfasis en Aplicaciones Web.\n- Fundamentos de programacion con Python.',
            p3:' Visión: "Ser reconocido como un referente en la creación de interfaces de usuario excepcionales, contribuyendo al desarrollo de productos digitales que marquen tendencia en la industria. Aspiro a liderar proyectos que combinen tecnología y diseño para ofrecer experiencias digitales únicas y significativas.',
            p4:' - Uso y configuración de sistemas de control de versiones, Git y GitHub. \n- Desarrollo de páginas web responsivas haciendo uso de marcos de trabajo y preprocesadores. \n- Identificación de componentes al analizar las interfaces a maquetar. \n- Maquetación haciendo uso de HTML, CSS de acuerdo a las condiciones y restricciones del área de \n- Diseño o especificaciones del cliente final. \n- Habilidad de trabajo en equipo por medio de metodologías ágiles de desarrollo de software y trabajo por medio de SCRUM.\n- Manipulación de NPM (Node Package Manager) y el entorno de NodeJs. \n- Realizar aplicaciones FrontEnd haciendo uso de ReactJs Básico - Intermedio.',
            p5:'En mi tiempo libre, también me dedico a aprender y experimentar con nuevas tecnologías, lo que me mantiene actualizado en las últimas tendencias en desarrollo web.',
            p6:' Ademas he trabajado con diferentes lenguajes de programación como JavaScript, PHP, HTML, CSS, React.Js.',
        }
    },
    skills:{
        url:'/conocimientos',
        name:'Conocimientos',
        subTitle1:'Estas son, algunas de las tecnologías que utilizo para crear aplicaciones del lado del cliente',
        subTitle2:'Estas son, tegnologias complementarias',
        front:{
            card0:{
                name:'HTML 5',
                img:'logos/html-5.png',
            },
            card1:{
                name:'CSS 3',
                img:'logos/css-3.png',
            },
            card2:{
                name:'SASS',
                img:'logos/sass.png',
            },
            card3:{
                name:'Bootstrap 5',
                img:'logos/bootstrap-5.png',
            },
            card4:{
                name:'JavaScript ',
                img:'logos/javascript.png',
            },
            card5:{
                name:'React Js',
                img:'logos/logo192.png',
            },
            card6:{
                name:'Material Ui',
                img:'logos/material-ui.svg',
            },
            card7:{
                name:'Styled Components',
                img:'logos/styled-components.png',
            },
        },
        others:{
            card0:{
                name:'NodeJs',
                img:'logos/nodejs.png',
            },
            card1:{
                name:'Git',
                img:'logos/git.png',
            },
            card2:{
                name:'GitHub',
                img:'logos/github.png',
            },
            card3:{
                name:'Zombie',
                img:'logos/zombie.png',
            },
            card4:{
                name:'Ubuntu 22',
                img:'logos/ubuntu.png',
            },
            card5:{
                name:'Linux',
                img:'logos/linux.png',
            },
            card6:{
                name:'Visual Studio Code',
                img:'logos/visual.png',
            },
        }

    },
    work:{
        url:'/proyectos',
        name:'Proyectos',
        cards:{
            card0:{
                title:'Itic Colombia',
                img:'work/iticco.png',
                url:'https://www.iticco.com',
            },
            card1:{
                title:'Faccel',
                img:'work/faccel.png',
                url:'https://www.faccel.com/',
            },
            card2:{
                title:'Grupo Vtek',
                img:'work/vtek.png',
                url:'https://www.grupovtek.com',
            },
            card3:{
                title:'Itic Peru',
                img:'work/iticpe.png',
                url:'https://iticpe.com',
            },
            card4:{
                title:'Salud segura',
                img:'work/app.jpg',
                url:'https://gallery.appinventor.mit.edu/?galleryid=326d5126-8fa5-4759-b1cd-882909916862',
            }
        }

    },
    contact:{
        url:'/contacto',
        name:'Contacto',
        card:{
            
        }

    },
    politica:{
        url:'/politica-de-proteccion-de-datos-personales',
        name:'Politica de Privacidad',
        cards:{
            card0:{
                id:'politicas1',
                text0:'Cómo trato sus datos',
                text1:'En www.jansdesarrolladorweb.com, estamos comprometidos con la protección de la privacidad y los datos personales de nuestros usuarios. Nosotros no recolectamos, almacenamos ni procesamos datos personales de nuestros usuarios a través de nuestro sitio web. Por lo tanto, puede estar seguro de que no compartiremos, venderemos ni transferiremos sus datos personales a terceros.',
            },
            card1:{
                id:'politicas2',
                text0:'',
                text1:'Durante su visita no se procesa ninguna analítica de usuario ni ninguna otra métrica, y el servidor no escribe ningún archivo de registro, lo que significa que su dirección IP no se almacena en ningún sitio.',
            },
            card2:{
                id:'politicas3',
                text0:'Transferencia de datos segura',
                text1:'Este sitio web se transmite usando HTTPS, encriptando todos los datos entre el navegador y el servidor. Es posible que su proveedor de red pueda ver que está cargando archivos de este sitio, pero no su contenido. Esto también le protege de terceros que intercepten los datos, por ejemplo, mientras navega por una red WiFi pública, o de proveedores de red que inyecten anuncios en este sitio.',
            },
            card3:{
                id:'politicas4',
                text0:'Responsable del tratamiento',
                text1:'Si tiene alguna pregunta o inquietud sobre nuestra política de protección de datos personales, no dude en ponerse en contacto con nosotros a través de nuestro correo de contacto.\n\ncontacto@jansdesarrolladorweb.com',
            },
            card4:{
                id:'politicas5',
                text0:'Reclamación oficial',
                text1:'Está en su derecho de presentar una queja oficial ante la autoridad responsable de la protección de datos, si cree que este sitio web viola su privacidad. No obstante, preferiría que se pusiera en contacto conmigo personalmente en primer lugar; en caso contrario, póngase en contacto con: \n\nwww.sic.gov.co',
            },

        }
    }
}]